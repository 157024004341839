.loginPage{
    background-size: cover;
    width: 100%;
    height: 100vh;
    background: transparent linear-gradient(178deg, #1839f0 0%, #0c3349 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    overflow: hidden;
}
.boxlogin {
    position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
	background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 70px;
    padding: 0 40px;
    opacity: 1;
    
}

.logologin {
    width: 15em;
    margin-top: 30px;
    margin-bottom: 60px;
}
.logologin img {
        width: 100%;
        height: 100%;
}
.loginForm{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.boxlogin .inputbox {
    width: 100%;
    margin-bottom: 30px;
} 
.boxlogin .inputbox input {
	width: 91%;
	padding: 10px 10px;
	font: normal normal normal 19px/26px Segoe UI;
    color: #707070;
    opacity: 0.84;
	border: 1px solid #707070;
    border-radius: 18px;
	outline: none;	
	background: transparent;

}
.submitLogin{
    padding: 10px 70px;
    font: normal normal normal 17px/22px Segoe UI;
    letter-spacing: 0px;
    color: #FAFAFA;
    background: #1839f0;
    border-radius: 28px;
    border: none;
    opacity: 1;
    margin-bottom: 50px;
    outline: none;
}
.submitLogin:hover{
    cursor: pointer;
}

@media only screen and (max-width:400px){
    .loginPage{
        background: #fff;
    }
    .boxlogin{
        border: none;
    }

}