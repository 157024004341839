footer{
    width: 100%;
}
.footer {
    background: #1c1e20;
    width: 100%;
    height:90px;
	margin-top: 50px ;
	
}

.footer .footer-brand {
	float: 	left;
	margin-top: 18px;
	width: 160px;
	outline: none;
	
}
.icon {
	color: rgb(240, 240, 240);
	margin-top: 10px;
	margin-left: 10px;
	letter-spacing: 17px;
	column-gap: 20px;
	
	
}
.icon i {
	cursor: pointer;
}


.footer .footer-brand h2 {
	color: #fff;
	text-transform: uppercase;
	font-weight: 800;
	-webkit-transition: all .2s ease 0s;
    -moz-transition: all .2s ease 0s;
    -o-transition: all .2s ease 0s;
    transition: all .2s ease 0s;
}

.footer-right {
    display: flex;
	text-align: center;
}
.footer .navbar-nav{
    display:flex;
    flex-direction: row;
    margin-top: 18px;
}
.footer .navbar-nav .nav-item {
	margin: 0px 15px;
	
}
.footer .navbar-nav a.nav-link {
	font-size: 14px;
	letter-spacing: 0.8px;
	color:#fff;

	transition: all 0.1s;
	margin-top: 9px;

}

.footer .navbar-nav .nav-link:hover,
.footer .navbar-nav .nav-link.active,
.footer .navbar-nav .nav-link.show,
.footer .navbar-nav .show>.nav-link {
	color: rgb(221, 216, 216);
    text-shadow: 0.5px 0.5px;
}


.lastline{
    width:100%;
    height:40px;
    background-color: rgb(49, 91, 206);
}

  @media (max-width: 680px) {
	
	.footer-res {
		display: flex;
		flex-direction: column;
	}
	.lastline{
		height:70px;
		background-color: rgb(49, 91, 206);
	}
	.footer-right {
		margin-top: 10px;
	}
	.navItems{
		flex-wrap: wrap;
	}

  }
  @media (max-width: 480px) {
	.lastline{
		height:140px;
		background-color: rgb(49, 91, 206);
	}
	.icon{
		display: grid;
		margin-top: 5px;
	}
	.icon i{
		margin-top: 10px;
	}

  }

  .navItems{
	  display: flex;
  }