.titleEstimate{
	margin-top: 50px;
    margin-left: 10%;
	font-size: 25px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color:#494c86;
  }

.titleEstimate::after{
	content: '';
	background: #1E90FF;
	height: 5px;
	width: 80px;
	display: block;
	transform: translateY(8px);
}

.answer:hover {
    background-color: #cfe0fb;
    color: rgb(0, 0, 0);
}
.answer {
    margin-top: 20px;
    background-color: rgb(245, 248, 255);
    margin-left: 7%;
    margin-right: 7%;
    position: relative;
    cursor: pointer;
}

.reset {
    margin-top: 20px;
    color: white;
    background-color: rgb(55, 83, 148);
    margin-left: 7%;
    margin-right: 7%;
    padding: 10px 20px;
    position: relative;
}

.reset:hover {
    background-color: #5391f5;
    color: rgb(255, 255, 255);
}

.result-section {
    margin-top: 150px;    
}

.answersCards{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: none;
    padding: 40px 0 40px 0;
}

.answersCards .card{
    position: relative;
    margin-top: 20px;
    height: 200px;
    width: 300px;
    background: #fff;
    box-shadow: 0 10px 22px rgba(0,0,0,0.09), 0 10px 10px rgba(0,0,0,0.09);
    cursor: pointer;
}
.answersCards  .card:hover{
    box-shadow: 0px 0px 20px 1px #e0e6ff;
    background-color: rgba(188, 217, 255, 0.5);

}
.card:hover .cardImg{
   filter: brightness(110%);
   top:10px;
}
.card:hover .cardbg{
    background-color:#3894fd ;
    
 }

.answersCards .cardImg{
    width: 160px;
    position: absolute;
    right: -20px;
}

.answersCards i{
    position: absolute;
    top: 10px;
    left: 10px;
}

.cardbg{
    height: 120px;
    background-color: #a1cdff;
}
.cardbg1{
    height: 120px;
    background-color: #5391f5;
}

.cardContent{
    padding: 0 20px;
}
.cardContent h3{
    font-weight: 600;
}

.cardContent h4{
    margin-top: 20px;
    font-weight: 500;
}

.popupDiv{
    padding:40px;
    background-color:#dee2e6;
    border-radius: 10px;
    display: flex;
    justify-items: center;
    justify-content: center;
    
}
@media screen and (max-width: 600px) {
    .popupDiv{
        flex-direction: column ;
    }
    .popupDiv button{
        margin-bottom: 20px;
    }
    .popupDiv input{
        width: 100%;
    }
    .form-control{
        padding: 0;
    }
    
}
.popupDiv button{
    color: white;
    background-color: rgb(55, 83, 148);
    margin-left: 6%;
    position: relative;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 5px 20px;
    cursor: pointer;
}


.popupDiv button:hover {
    background-color: #5391f5;
    color: rgb(255, 255, 255);
}
.popupDiv button:active {
    background-color: #5391f5;
    color: rgb(255, 255, 255);
}

.popupDiv i{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.form-group {
    padding: 0 ;
  }
.form-control {
    height: 35px;
    border: none;
    box-shadow: 0.5rem 0.5rem 0.3rem rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 0 12px;
    color: #000;
    transition: all 0.5s ease;
    outline: none;
    width: 100%;
  }

.form-control:focus {
    border-color: #007bc7;
  }

  .form-group textarea{
    height: 60px;
  }

  .card-result{
    background: #d1ecf1;
    color: #43a1b4;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
  }