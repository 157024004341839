.title{
	margin-top: 40px;
	font-size: 30px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color:#0b2e7ee3;
  }

.title::after{
	content: '';
	background: #1E90FF;
	height: 5px;
	width: 80px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	display: block;
	transform: translateY(8px);
}
.card-wrapper{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	justify-content: center;
	flex-direction: row;
}
.ourmindset-section {
	padding: 40px 0px 40px 0px;
	
	background-image: url("../../Assets/ourmind.png");
	background-size:cover;
    min-height: 450px;
	margin-bottom: 60px;
}

.mindset-box {
    margin-top:30px;
	max-width: 250px;
    min-height: 300px;
	border-radius: 20px;
	position: relative;
	padding: 20px;
	background: #ffffff;
    margin-left:20px;
	box-shadow: rgb(108, 109, 112) 0px 0px 20px -10px;
	margin-bottom: 30px;
	padding: 35px 20px 35px 20px
	
  }
  .card-wrapper :hover{
	  background-color: rgb(243, 246, 255);

}

  .mindsetimage{
	
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	
}
.pp {
	font-size: 15px;
	padding-top: 10px;
}
