

.card {
  width: 86%;
  box-shadow: 0px 0px 7px 1px #f1f1f1;
  font-family: exo-bold;

  
}
.card:hover {
  box-shadow: 0px 0px 20px 1px #e0e6ff;
  cursor: pointer;
}
.card:hover .button-primary {
  transform: translate(5px, 0);
}


.card-body {
 padding-left: 5%;
  background-color: #fff;
  width: 100%;
}
.card-body1{
  padding-left: 5%;
}

.date {
  font-size: 11px;
  margin-bottom:10px ;
  font-family: exo-light;
  font-weight: 600;
  color: grey;
}

.body-content {
  padding-bottom: 20px;
  font-size: 17px;
  font-family: exo-regular;
  line-height: 1.8;
}

.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: transparent;
  color: #1c66ac;
  font-size: 15px;
  transition: 0.25s;
  cursor: pointer;
}
.button-primary i {
  padding-right: 4px;
}
.cardheader {
  padding-top: 130px;
  font-family: exo-bold;
  text-align: center;
}


.app-card-list .card {
  margin: 60px 20px 20px 60px;
  display: inline-block;
}

@media (max-width: 800px) { 

  .app-card-list .card {
    margin: 60px 20px 20px 30px;
    
  }
	
	
	}


.popupDiv .cv-input {
  color: transparent;
  margin-left: 5%;
}
.popupDiv  .cv-input::-webkit-file-upload-button {
  visibility: hidden;
}
.popupDiv  .cv-input::before {
  content: 'Ajouter votre CV';
  color:#000;
  display: inline-block;
  background: linear-gradient(to top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.popupDiv .cv-input:hover::before {
  border-color: black;
}
.popupDiv .cv-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.titles{
  font-weight: 700;
}