.footer-section {  
	padding: 40px 0px 40px 0px;
	background:rgb(195, 219, 250 , 0.3);
	background-size:cover;
   

}

.titlefoot{
	margin-top: 40px;
	font-size: 25px;
    font-family: exo-bold;
	font-weight: 300;
	letter-spacing: 0.5px;
	color:#494c86;
  }
  .titlefoot::after{
	content: '';
	background: #494c86;
	height: 3px;
	width: 80px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	display: block;
	transform: translateY(8px);
}
  
.footcontent{
      text-align: center;
      font-size: 20px;
      font-family: exo-light;
  }
.footdiv {
    padding-bottom: 35px;
}

@media (max-width: 800px) { 
	.footcontent{
		font-size: 15px;
		padding: 0px 40px 0px 40px;
	
	}
	.titlefoot{
		font-size: 20px;
	
	  }
	

}