header {
	position: fixed;
	z-index: 99999;
	width: 100%;
	background-color: transparent!important;
	border-bottom: 1px solid rgba(250,250,250,0.30);
	height: 60px;
	-webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
}

.navbar {
    background: rgb(184,210,239);
    background: linear-gradient(90deg, rgba(184,210,239,1) 0%, rgba(196,217,243,1) 35%, rgba(203,223,244,1) 100%);	
}

.navbar .navbar-brand {
	float: 	left;
	margin-top: 0px;
	outline: none;
	width: 170px;
}
.navbar .navbar-brand h2 {
	color: black;
	text-transform: uppercase;
	font-size: 24px;
	font-weight: 800;
	-webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
}

#navbarResponsive {
	z-index: 999;
}
.navbar-collapse {
	text-align: center;
}
.navbar .navbar-nav .nav-item {
	margin: 0px 10px;
}
.navbar .navbar-nav .nav-item span{
	cursor: pointer;
}
.navbar .navbar-nav a.nav-link {
	text-transform: capitalize;
	font-size: 15px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color:#494c86;
	transition: all 0.1s;
	margin-top: 5px;
}
.navbar .navbar-nav .nav-link:hover{
	color: rgb(22, 22, 22);
	text-shadow: 0.5px 0.5px;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .active>.nav-link,
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link.show,
.navbar .navbar-nav .show>.nav-link {
	color: black;
}
.navbar .navbar-toggler-icon {
	background-image: none;
}
.navbar .navbar-toggler {
	height: 50px;
	outline: none;
	border-radius: 15px;
	position: relative;
	margin-left: auto;
	
}
.navbar .navbar-toggler-icon:after {
	content: '\f0c9';
	color: rgb(41, 115, 184);
	font-size: 18px;
	line-height: 26px;
	font-family: 'FontAwesome';
}

/*----------------styling sub----------*/
.sub-content {
	position: absolute;
    overflow: hidden;
	max-height: 0;
}
.sub-content .sub-drop-item {
	color: black;
	font-size: 15px;
	border-bottom:1px solid black;
	

}
.sub-drop {
	padding: 0;
}
.sub-drop ul {
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
}
.sub-drop ul li .sub-drop-item { 	
	display: inline-block;
	padding: 5px 20px;
	margin-left: 5px;
}
.sub-drop ul li :hover {
	color: rgb(22, 22, 22);
	text-shadow: 0.5px 0.5px;
}

.navbar-collapse ul li:hover .sub-content {
	max-height: 400px;
	overflow: visible;
	transition: max-height 0.3s ease-in;
	-mobikit-transition: max-height 0.3s ease-in;
	-moz-transition: max-height 0.3s ease-in;
}

.myButton2 {
	background-color: #3f99dd;
	display:inline-block;
	cursor:pointer;
	color: #ffffff;
	margin-left: 10px;
	font-size: 13px;
	padding:8px 10px;

}

.myButton2:hover {
	background-color:#0095ff;

	color: white;
}
.myButton2:active {
	position:relative;
	top:1px;
}
.container-fluid{
	margin: auto;
	padding-right: 14%;
	padding-left: 14%;
	
}
.sub-drop {
	background-color: rgba(225, 238, 255, 0.83);
	box-shadow: #a0a9b8 0px 0px 10px;
	border-radius: 10px;
	width: 90px;
	padding-bottom:3px ;
	
}