.carousel-inner {
    margin-bottom: 70px;
    margin-top: 30px;
    max-width: 80%;
    border-radius: 15px;
   


}

.title-carousel {
    font-family: exo-bold;
    color: #0b2e7ee3;
    font-size: 30px;
}
.p-carousel {
    font-family: exo-regular;
    font-size: 17px;
}
.title {
    font-family: exo-bold;

}
.carousel-control-next-icon  {
    filter: invert(100%);
   
}
.carousel-control-prev-icon  {
    filter: invert(100%);
   
}

.carousel-indicators {
    filter: invert(100%);
    opacity: 60%;
  
}