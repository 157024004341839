
@font-face {
    font-family: 'Exo-bold';
    src: local('Exo-bold'), url(./Fonts/Exo-Bold.ttf) format('truetype');
    
  }
@font-face {
    font-family: 'Exo-regular';
    src: local('Exo-regular'), url(./Fonts/Exo-Regular.ttf) format('truetype');
    
  }
@font-face {
    font-family: 'Exo-light';
    src: local('Exo-light'), url(./Fonts/Exo-Light.ttf) format('truetype');
    
  }

  @font-face {
    font-family: 'Exo-SemiBold';
    src: local('Exo-SemiBold'), url(./Fonts/Exo-SemiBold.ttf) format('truetype');
    
  }


*{
    margin: 0;
    padding: 0;
}
html{
    overflow-x: hidden;
}


body{
    box-sizing: border-box;
    font-family: Exo-regular;
    overflow-y: auto;
    height: auto;
    transition: all 0.5s;
    overflow-x: hidden;
}
p {
	margin-bottom: 0px;
    font-family: exo-regular;
	font-size: 17px;
	color: #252525;
	line-height: 25px;
}
a {
	text-decoration: none!important;
}

/******* styling scrollbars ******/

::-webkit-scrollbar{
    width: 6px;
    height: 1px;
}

::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px #a5aaad;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb{
    background: #2a6796;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
    background: #a5aaad;
}






