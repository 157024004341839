main{
    background: #f3f4f5;
    width: 100%;
    float: right;
    overflow-y: auto;
    height: 100vh;
}
.main-container{
    padding: 20px 35px;
}

.main-title{
    display: flex;
    align-items: center;
}

.main-title > img {
    max-height: 100px;
    object-fit: contain;
    margin-right: 20px;
}

.main-greeting > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.main-greeting > p {
    font-size: 15px;
    font-weight: 700;
    color: #a5aaad;
}

.main-cards {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin: 20px 0;
}

.main-cards .card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
    border-radius: 5px;
    background: #fff;
    padding: 25px;
}
.main-cards .card:hover{  
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
}

.card-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-inner > span {
    font-size: 25px;
    color:#2e4a66;
}
.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.charts-left{
    height: 1000px;
    overflow-y: scroll;
    padding: 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
}

.charts-left-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.charts-left-title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.charts-left-title > div > p {
    font-size: 24px;
    font-weight: 700;
    color: #a5aaad;
}
.charts-left-title > i {
    color: #fff;
    font-size: 20px;
    background: #E61010;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 15px;
}

.charts-right{
    height: 1000px;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
}


.charts-right-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charts-right-title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.charts-right-title > div > p {
    font-size: 14px;
    font-weight: 700;
    color: #a5aaad;
}

.charts-right-title > i {
    color: #fff;
    font-size: 20px;
    background: #E61010;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 15px;
}

.charts-right-cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
}
.card-right:hover{
    box-shadow: 0px 0px 20px 1px #e0e6ff;
    cursor: pointer;
}


.card-right:nth-child(4n) {
    background: #d1ecf1;
    color: #35a4ba;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card-right:nth-child(4n+1) {
    background: #d2f9ee;
    color: #38e1b0;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card-right:nth-child(4n+2) {
    background: #d6d8d9;
    color: #3a3e41;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

.card-right:nth-child(4n+3) {
    background: #fddcdf;
    color: #f65a6f;
    text-align: center;
    padding: 25px;
    border-radius: 5px;
    font-size: 14px;
}

@media only screen and (max-width: 855px){
    .main-cards{
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts{
        grid-template-columns: 1fr;
        margin-top: 30px;
    }
    
}
.card-list .card {
    margin: 10px 20px 20px 0px;
    display: inline-block;
  }

  .offersCards{
    height: 350px;
    overflow-y: scroll;
    width: 100%;
    padding: 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
  }
 .card-right i{
     position: relative;
     float: right;
 }

 .offers-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.offers-title > div > h1 {
    font-size: 24px;
    color: #2e4a66;
    margin-bottom: 5px;
}

.offers-title > div > p {
    font-size: 24px;
    font-weight: 700;
    color: #a5aaad;
}
.offers-title > i {
    color: #fff;
    font-size: 20px;
    background: #E61010;
    border-radius: 200px 0px 200px 200px;
    border: 0px solid #000;
    padding: 15px;
    cursor: pointer;
}
.popupDiv1{
    margin-top: 60px;
    padding:40px;
    background-color:#dee2e6;
    border-radius: 10px;
    display: flex;
    justify-items: center;
    justify-content: center;
    height: 550px;
    overflow-x: scroll;

}
.popupDiv1 span{
    color: white;
    background-color: rgb(55, 83, 148);
    margin-left: 6%;
    position: relative;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 5px 20px;
    cursor: pointer;
}


.popupDiv1 button:hover {
    background-color: #5391f5;
    color: rgb(255, 255, 255);
}
.popupDiv1 button:active {
    background-color: #5391f5;
    color: rgb(255, 255, 255);
}

.popupDiv1 i{
    position: absolute;
    top: 80px;
    right: 20px;
    cursor: pointer;
}
.popupDiv1 h4{
    position: absolute;
    top: 10px;
    left: 10px;
    margin-bottom: 20px;
}

.info__card{
    margin-top: 20px;
    width: 100%;

}

.form-group textarea{
    height: 100px;
    width: 90%;

}
.charts-right-title i{
    cursor: pointer;
}
.form-group input{
    width: 90%;
    margin-bottom: 5px;
}
.form-group {
    width: 90%;
}

.logout button{
    background-color: #35a4ba;
}