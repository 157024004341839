@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  margin-top: 0;
  padding: 0;
  font-family: 'exo', sans-serif
}



h3 {
  text-align: center;
  margin-top: 80px;
}

.accordion {
  font-size: 18px;
  font-family: Ubuntu;
 
}

.accordion__button {
  border: 0;
  margin: 15px 0;
  padding: 20px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  border: solid rgb(214, 213, 223) 2px;
  background-color: rgb(255, 255, 255 , 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
  cursor: pointer;
}

.accordion__button span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(146, 146, 146);
  font-size: 15px;
}

.accordion__button:focus {
  outline: none;
  color: rgb(8, 111, 245);
  
}

.accordion__content {
  padding: 0 20px;
  opacity: 0;
  font-size: 18px;
  transition: all .5s;
  height: 0;
  margin: 0;
  margin-top: -16px;
  border-radius: 0px 0px 10px 10px;
  border: solid rgb(214, 213, 223) 2px;
  background-color: rgb(255, 255, 255 , 0.8);
  border-top: 0;
}

.accordion__content.active {
    height: auto;
    opacity: 1;
    padding: 20px;
}

