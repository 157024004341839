*#aboutUs{
	padding: 80px 0px;
}*/
html, body {
	margin-top: 0;
	padding: 0;

  }



.circle {
	height: 150px;
	width: 150px;
	position: absolute;
	margin-left: 586px;
	margin-top: 23%;
	border-radius: 50%;
	background-color: #c0e9fa;
  }

  .circle2 {
	height: 200px;
	width: 200px;
	position: absolute;
	margin-left: 1000px;
	margin-top: -5%;
	border-radius: 50%;
	background-color: #c0e9fa;
  }

.h3 {
	
    text-align: center;
    font-family: exo-bold;	
	  
}  
  

.about-section {
	margin-top: 5%;
	padding: 40px 0px 40px 0px;
	background-color: #e6f0fc;
}
.form-box {
	max-width: 400px;
  justify-content: center;
	position: relative;
	border-radius: 5%;
	
  }
.form-box form{
	max-width: 100%;
}  

 
  label{
	  font-size:13px;
  }
  input{
	width: 100%;
	height: 60px;
	font-size: 16px;
	background: rgb(236, 233, 233);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-sizing: border-box;
    outline: none !important;
	
  }
textarea{
	height: 100px;
	width: 100%;
	font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-sizing: border-box;
    outline: none !important;
}
  .icons {
    margin-top: 100px;
	margin-bottom: 50px;
	display: flex;
	column-gap: 20px;
	
  }
  .icons i{
	  margin-left: 20px;
  }
  
.icons :hover {  
	color: rgb(80, 113, 219);
	cursor: pointer;	
  }



.contact{
	font-size: 30px;
	font-weight: 300;
	letter-spacing: 0.5px;
	color:#494c86;
  }

.contact::after{
	content: '';
	background: #ccc;
	height: 2px;
	width: 280px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	display: block;
	transform: translateY(8px);
}
.sendbtn{
    align-self: center;
    width: 100%;
    background: #1E90FF;
    box-sizing: border-box;
    border-radius: 20px;
    border: none;
    color: #FFF;
    font-size: 20px;
	padding: 2px 150px;
    position: relative;
    top: 10px;
}
.sendbtn:hover{
    cursor: pointer;
    text-shadow: 0.5px 0.5px;

}
.emailndphone{
	margin-top: 20px;
	display:flex;
}
.emailndphone input{
	margin-left: 5px;
}







#feedback-form {
    height: 460px;
    background-color: #ffffff;
    padding: 60px 30px 0px;
    box-shadow: 1px 4px 10px 1px rgb(212, 212, 212);
    font-family: sans-serif;
  }
  
  #feedback-form * {
      box-sizing: border-box;
  }
  
  #feedback-form h2{
    text-align: center;
    font-family: 'exo', sans-serif;
    margin-bottom: 30px;
  }
  
  #feedback-form input {
    margin-bottom: 15px;
    
  }
  
  #feedback-form input[type=text] {
    display: block;
    height: 40px;
    padding: 20px 16px;
    max-width: 100%;
    border: none;
    background-color: #f3f3f3;
  }
  #feedback-form input[type=email] {
    display: block;
    height: 40px;
    padding: 20px 16px;
    width: 100%;
    border: none;
    background-color: #f3f3f3;
  }
  
  #feedback-form label {
    color: #777;
    font-size: 0.8em;
  }
  
  #feedback-form input[type=checkbox] {
    float: left;
  }
  
  
  
  
  #feedback-form button[type=submit] {
    display:flex;
    margin: 20px auto 0;
    max-width: 150px;
    height: 40px;
    border-radius: 25px;
    border: none;
    color: #eee;
    font-weight: 700;
    box-shadow: 1px 4px 10px 1px #aaa;
    
    background: #207cca; /* Old browsers */
  
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#9f58a3',GradientType=1 ); /* IE6-9 */
  }
  #feedback-form button[type=submit]:hover {
      color:rgb(255, 255, 255);
      background-color: rgb(123, 164, 230);
      

  }
  #feedback-form textarea{
    display: block;
    height: 80px;
    padding: 10px 16px;
    width: 100%;
    border: none;
    background-color: #f3f3f3;
  }




  @media(max-width:600px){
    .form-box {
      max-width: 350px;   
      max-height: 420px;
    
      
      }
  }
