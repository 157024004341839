
#header h2 {
    font-family: 'exo', sans-serif
}
#feedback-form {
    
    
    width: 1000px;
    background-color: #ffffff;
    padding: 20px 50px 40px;
    box-shadow: 1px 4px 10px 1px rgb(212, 212, 212);
    font-family: sans-serif;
  }

  #feedback-form1 {
    width: 1000px;
    background-color:rgb(255, 255, 255,0);
    padding: 20px 50px 40px;
    font-family: sans-serif;
  }
  
  #feedback-form * {
      box-sizing: border-box;
  }
  
  #feedback-form h2{
    text-align: center;
    font-family: 'exo', sans-serif;
    margin-bottom: 30px;
  }
  #feedback-form1 h2{
    text-align: center;
    font-family: 'exo', sans-serif;
    margin-bottom: 30px;
  }
  
  #feedback-form input {
    margin-bottom: 15px;
    
  }
  
  #feedback-form input[type=text] {
    display: block;
    height: 32px;
    padding: 6px 16px;
    width: 100%;
    border: none;
    background-color: #f3f3f3;
  }

  #feedback-form1 input[type=text] {
    display: block;
    height: 50px;
    margin-top: 12px;
    padding: 10px 20px;
    width: 100%;
    border:2px solid rgb(219, 219, 228);
    box-shadow: rgb(79, 83, 83) 0px 0px 18px -15px;
   
    background-color:rgb(255, 255, 255 , 0.8);
  }
  #feedback-form1 input[type=email] {
    display: block;
    height: 50px;
    margin-top: 12px;
    padding: 10px 20px;
    width: 100%;
    border:2px solid rgb(219, 219, 228);
    box-shadow: rgb(79, 83, 83) 0px 0px 18px -15px;
   
    background-color:rgb(255, 255, 255 , 0.8);
  }
  
  #feedback-form label {
    color: #777;
    font-size: 0.8em;
  }
  

  
  
  
  #feedback-form button[type=submit] {
    display: block;
    margin: 20px auto 0;
    width: 150px;
    height: 40px;
    border-radius: 25px;
    border: none;
    color: #eee;
    font-weight: 700;
    box-shadow: 1px 4px 10px 1px #aaa;
    cursor: pointer;
    background: #207cca; /* Old browsers */
  
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#9f58a3',GradientType=1 ); /* IE6-9 */
  }
  #feedback-form button[type=submit]:hover {
      color:rgb(255, 255, 255);
      background-color: rgb(123, 164, 230);
      cursor: pointer;
      

  }
  #feedback-form1 button[type=submit]:hover {
    color:rgb(255, 255, 255);
    background-color: rgb(28, 134, 255);
    

}
  #feedback-form1 button[type=submit] {
    display: block;
    margin: 20px auto 0;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    border: none;
    color: #eee;
    font-weight: 700;
    box-shadow: 1px 4px 10px 1px #aaa;
    cursor: pointer;
    outline: none;
    background: #207cca; /* Old browsers */
  
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#9f58a3',GradientType=1 ); /* IE6-9 */
  }

  .textera {
    min-height: 50px;
    margin-top:10px
    
  }
