.banner {
	background-image: url(../../Assets/banner-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 150px 0px;
	padding-top: 150px;
	background-position: center center;
}
.txt{
	margin-bottom: 40px;
}
.txt2 {
	color: #0a1635e3;
	font-family: exo-SemiBold;
}

@media (max-width: 1000px) { 
	.txt2 {
		font-size: 35px;
	}

}
.buttons {
	justify-items: center;

}


.myButton {
	background-color: none;
	border-radius:6px;
	border:2px solid rgb(14, 50, 168);
	display:inline-block;
	cursor:pointer;
	color: rgb(80, 113, 219);
	font-size:15px;
	margin-bottom: 10px;
	padding:10px 15px;
	text-decoration:none;
}
.myButton:nth-child(1){
	margin-top: 30px;
	background-color:#0b2e7ee3;
	color: white;
}
.myButton:hover {
	background-color:#1a90eb;
	border:2px solid rgb(160, 199, 231);
	color: white;
}
.myButton:active {
	position:relative;
	top:1px;
}

.floating {
	animation-name: floating;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	margin-bottom: 30px;
	margin-top: 5px;
}

@keyframes floating {
	0% { transform: translate(0, 0px); }
	50% { transform: translate(0, 15px); }
	100% { transform: translate(0, -0px); }	
}
.container-fluid{
	margin: auto;
	padding-right: 14%;
	padding-left: 14%;
	
}